.container {
  scroll-behavior: smooth;
}

.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.heroContainer {
  box-sizing: border-box;
  align-items: center;
  background: radial-gradient(
    65.1% 50% at 50% 100%,
    rgba(242, 140, 234, 0.61) 0%,
    rgb(59, 31, 165) 100%
  );
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  height: 100vh;
  width: 100%;
  left: 0px;
  overflow: visible;
  padding: 10px;
  top: 0px;
  z-index: 1;
  color: #fff;
}

.title {
  margin-top: 20px;
  margin-bottom: 45px;

  font-size: 32px;
  font-family: "Lekton", sans-serif;
  text-align: center;

  flex-shrink: 0;
  position: relative;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-style: normal;
  font-family: "Lekton", serif;
  color: #ffffff;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  max-width: 100%;
  word-wrap: break-word;

  @media screen and (min-width: 768px) {
    margin-top: 100px;
    margin-bottom: 45px;
    font-size: 53px;
  }
}

.column {
  @media screen and (min-width: 768px) {
    width: 550px;
  }
}

.blend {
  mix-blend-mode: overlay;
}

.description {
  flex-shrink: 0;
  flex: 1 0 0px;
  height: auto; /* 265px */
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: visible;
  position: relative;
  font-weight: 400;
  font-style: normal;
  font-family: "Outfit", sans-serif;
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 0px;
  line-height: 1.4;
  text-align: center;

  margin: 0 0 45px 0;
  @media screen and (min-width: 768px) {
    font-size: 27px;
  }
}

.input {
  box-sizing: border-box;
  width: 100%;
  line-height: 1.4em;
  outline: none;
  border: none;
  webkit-appearance: none;
  padding: 20px;
  border-radius: 50px;
  font-family: "lekton Regular", "lekton", "Inter", system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 16px;
  background: rgb(255, 255, 255);
  color: rgb(34, 34, 34);
  box-shadow: inset 0 0 0 1px transparent;
  margin-bottom: 16px;
}

.info {
  font-family: "Lekton", sans-serif;
  line-height: 1.2;
  font-size: 16px;
}

.button {
  width: 100%;
  line-height: 1.4em;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  padding: 20px;
  font-family: "lekton Regular", lekton, Inter, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 16px;
  background: #232323;
  color: rgb(255, 255, 255);
  z-index: 1;
  opacity: 1;
  margin-top: 20px;
  font-weight: 400;
}

.partners {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  background-color: rgb(233, 233, 233);
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 2;
  padding: 20px 100px;
  opacity: 0.5;
}

.toBottom {
  position: absolute;
  bottom: 20px;
  left: 0%;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 1;
  color: white;

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-family: "Lekton", sans-serif;
  font-weight: 300;
  font-size: 16px;

  @media screen and (min-width: 768px) {
    display: flex;
    left: 50%;
  }
}

.copyContainer {
  background-color: #f4f4f4;
  display: flex;
  color: #121212;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Outfit", georgia, sans-serif;
    font-weight: 700;
  }
}

.copy {
  padding: 0px 8px;
  margin: 0 auto;

  font-family: georgia, "Outfit", sans-serif;
  font-size: 18px;
  font-stretch: 100%;
  font-style: normal;
  font-feature-settings: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 300;
  line-height: 34px;
  color: rgb(54, 54, 54);

  @media screen and (min-width: 768px) {
    padding: 0px 16px;
    max-width: 600px;

    font-size: 20px;
  }
}

.firstCopy {
  margin-top: 108px;
}

.copyBlock {
  margin-top: 48px;
}

.authorPhoto {
  object-fit: cover;
  border-radius: 50%;
  max-width: 100px;
  max-height: 100px;
}

.author {
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 16px;
}

.ctaBlock {
  display: flex;
  justify-content: center;
  margin: 36px 0 48px;
}

.authorSocialMedia {
  display: flex;
  gap: 12px;

  & > a {
    color: rgb(34, 34, 34, 0.8);
  }
}

/* button:hover {
  background: rgba(34, 34, 34, 0.8);
} */
