.fabulous,
.fabulous *,
.fabulous :after,
.fabulous :before,
.fabulous:after,
.fabulous:before {
  border: 0 solid;
  box-sizing: border-box;
}
.fabulous {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background: #232323;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: "lekton Regular", lekton, Inter, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#232323, #fff);
  padding: 0;
  text-transform: uppercase;
}
.fabulous:disabled {
  cursor: default;
}
.fabulous:-moz-focusring {
  outline: auto;
}
.fabulous svg {
  display: block;
  vertical-align: middle;
}
.fabulous [hidden] {
  display: none;
}
.fabulous {
  border-radius: 99rem;
  border-width: 2px;
  overflow: hidden;
  padding: 0.8rem 3rem;
  position: relative;
}
.fabulous span {
  mix-blend-mode: difference;
  position: relative;
  z-index: 1;
}
.fabulous:after,
.fabulous:before {
  background: #fff;
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(var(--progress, 0%) / 2),
    0 calc(var(--progress, 0%) / 2),
    0 0,
    0 calc(100% - var(--progress, 0%) / 2),
    100% calc(100% - var(--progress, 0%) / 2),
    100% 100%,
    0 100%,
    0 calc(100% - var(--progress, 0%) / 2)
  );
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(var(--progress, 0%) / 2),
    0 calc(var(--progress, 0%) / 2),
    0 0,
    0 calc(100% - var(--progress, 0%) / 2),
    100% calc(100% - var(--progress, 0%) / 2),
    100% 100%,
    0 100%,
    0 calc(100% - var(--progress, 0%) / 2)
  );
  content: "";
  inset: 0;
  position: absolute;
  transform: translateX(calc(100% - var(--progress, 0%) / 1.99));
  transition: transform 0.2s ease, -webkit-clip-path 0.2s ease;
  transition: clip-path 0.2s ease, transform 0.2s ease;
  transition: clip-path 0.2s ease, transform 0.2s ease,
    -webkit-clip-path 0.2s ease;
}
.fabulous:after {
  transform: translateX(calc(-100% + var(--progress, 0%) / 1.99));
}
.fabulous:hover {
  --progress: 100%;
}

.shaker {
  -webkit-animation: headShake 0.8s;
  animation: headShake 0.8s;
}
@-webkit-keyframes headShake {
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    transform: translateX(0);
  }
}
@keyframes headShake {
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    transform: translateX(0);
  }
}
