.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #161616;
  padding: 24px 0;
  color: white;
  font-family: "Lekton", georgia, sans-serif;
}

.info {
  padding: 16px 0;
  font-size: 14px;
  text-align: center;

  a {
    color: white;
    text-decoration: underline;
  }

  a:hover {
    color: #f5f5f5;
  }

  a:visited {
    color: white;
  }
}
