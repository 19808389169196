.root {
  font-family: "Lekton", georgia, sans-serif;
  font-weight: 600;
  border-radius: 20px;
  background-color: #fff;
  color: rgb(54, 54, 54);
  border: 2px solid #d9e1ec;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.4s;
  text-decoration: none;
  display: none;
  text-decoration: none;

  &:hover {
    border-color: rgb(59, 31, 165);
    color: rgb(59, 31, 165);
  }

  @media screen and (min-width: 768px) {
    display: block;
  }
}
